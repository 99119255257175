import React from "react"
import Layout from "../components/layout"
import ProductsGrid from "../components/sections/ProductsGrid"
import Title from "../components/title"
import SidebarCategories from "./../components/SidebarCategories"

const ProductosPage = () => {
  return (
    <Layout>
      <div className="container mt-xs-3">
        <Title color="blue" title="PRODUCTOS" withBorder={true} />
      </div>
      <div className="container__fluid">
        <div className="grid">
          <div className="col-12 col-sm-4 col-lg-4 col-xl-2">
            <SidebarCategories />
          </div>
          <div className="col-12 col-sm-8 col-lg-8 col-xl-9">
            <ProductsGrid />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductosPage
