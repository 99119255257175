import { graphql, useStaticQuery } from "gatsby"

const useProducts = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsProducto {
        nodes {
          id
          nombre
          descripcion
          imagen {
            fluid(maxWidth: 191, maxHeight: 247) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  return data.allDatoCmsProducto.nodes.map(
    ({ nombre, imagen, descripcion, id }) => ({
      id,
      nombre,
      imagen,
      descripcion,
    })
  )
}

export default useProducts
