import React, { useState } from "react"
import useProducts from "../../../hooks/useProducts"
import Product from "./../../product"
import Modal from "./../../modal"
import Img from "gatsby-image"
import "./productsGrid.scss"

const ProductsGrid = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [productSelected, setProductSelected] = useState(null)
  const products = useProducts()

  const openModal = product => {
    setProductSelected(product)
    setIsOpen(true)
  }

  const closeModal = () => setIsOpen(false)

  return (
    <React.Fragment>
      <div className="products-grid">
        {products &&
          products.map(product => (
            <div
              key={product.id}
              className="maxwidth-100"
              style={{
                border: "1px solid var(--gray-primary)",
                padding: "2rem",
                width: "100%",
              }}
            >
              <Product
                onClick={() => openModal(product)}
                className="m-auto"
                name={product.nombre}
                image={product.imagen.fluid}
              />
            </div>
          ))}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <div className="grid">
          <div className="col-12 col-md-5">
            <div className="product-image-modal m-auto">
              <Img
                fluid={productSelected?.imagen?.fluid}
                className="m-auto"
                objectFit="contain"
              />
            </div>
          </div>
          <div className="col-12 col-md-7">
            <p className="bg-blue-secondary p-2 color-light text-center">
              {productSelected?.nombre}
            </p>
            <p className="product-description-modal">
              {productSelected?.descripcion}
            </p>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ProductsGrid
